(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

require('./autoreg.js');

$(document).ready(function () {
    $('article').prependTo($('.main-text'));
    $('article h1').appendTo($('.for-h'));

    //menu toggle
    $('#menu-toggle-but').click(function () {
        $(this).toggleClass('open');
        $(".topbar-nav").slideToggle('fast');
    });

    $('.slider').slick({
        autoplay: true
    });

    //random players
    function randomUser() {
        var playerNames = ["Rabbit Helpless", "Warm Foal", "Desire Kit", "Angel Dusty", "Sweety Frozen", "Heavy Wombat", "Lost Puma", "Vital Panda", "Rolling Sun", "Steel Runny", "Young Fox", "Needlessr", "Chipmunk Cult", "Indigo Puppy", "Dreaded Foal", "GOT Kit", "Angel Dusty", "LOL Frozen", "Silver Wombat", "Lost Banan", "Big Panda", "Rolling Sun", "Steel Runny", "Young Fox", "Needlessr", "ZAZ NOV", "FORD Puppy"];
        var namerand = Math.floor(Math.random() * playerNames.length);
        return playerNames[namerand];
    }

    $(".winner-box").each(function () {
        var randomNum = Math.floor(Math.random() * 10000 + 1000);
        randomNum = "$ " + randomNum;
        $(this).find(".winners-name").append(randomUser());
        $(this).find(".winners-prize").append(randomNum);
    });
    //random players
    // function randomUser() {
    //     var playerNames = ['Rabbit Helpless', 'Warm Foal', 'Desire Kit', 'Angel Dusty', 'Sweety Frozen', 'Heavy Wombat', 'Lost Puma', 'Vital Panda', 'Rolling Sun', 'Steel Runny', 'Young Fox', 'Needlessr', 'Chipmunk Cult', 'Indigo Puppy', 'Dreaded Foal', 'GOT Kit', 'Angel Dusty', 'LOL Frozen', 'Silver Wombat', 'Lost Banan', 'Big Panda', 'Rolling Sun', 'Steel Runny', 'Young Fox', 'Needlessr', 'ZAZ NOV', 'FORD Puppy'];
    //     var namerand = Math.floor(Math.random() * playerNames.length);
    //     return playerNames[namerand];
    // }
    // $('.game__slider-slide').each(function () {
    //     var randomNum = Math.floor(Math.random() * 10000 + 1000);
    //     randomNum = randomNum + ' RUB';
    //     $(this).find('.game-player').append(randomUser());
    //     $(this).find('.game-prize').append(randomNum);
    // });
    // $('.game-slot').each(function () {
    //     var randomNum = Math.floor(Math.random() * 30 + 113);
    //     $(this).find('.playing-now').append('Сейчас играют: ' + randomNum);
    // })

    // GAME SLIDER INITIALISATION

    // $('.game__slider').slick({
    //     infinite: true,
    //     speed: 300,
    //     slidesToShow: 5,
    //     arrows: false,
    //     autoplay: true,
    //     autoplaySpeed: 1500,
    //     responsive: [{
    //             breakpoint: 1250,
    //             settings: {
    //                 slidesToShow: 4,
    //             }
    //         },
    //         {
    //             breakpoint: 800,
    //             settings: {
    //                 slidesToShow: 3,
    //             }
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 2,
    //             }
    //         },
    //         {
    //             breakpoint: 420,
    //             settings: {
    //                 slidesToShow: 1,
    //             }
    //         }
    //     ]
    // });
});

},{"./autoreg.js":2}],2:[function(require,module,exports){
'use strict';

$('[data-link]').click(function () {
    var link = $(this).data('link');
    window.open('/' + atob(link), '_self');
});

},{}]},{},[1]);
